import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { mobileLarge } from "clutch/src/Style/style.mjs";
import type { TabsProps } from "clutch/src/Tabs/index.jsx";
import { Tabs } from "clutch/src/Tabs/index.jsx";
import type { ElementOf } from "ts-essentials";

import { ValorantConsoleData } from "@/game-val/constants/platform-constants.mjs";
import { useConsoleState } from "@/game-val/utils/console.mjs";
import { useInteractionEvent } from "@/util/use-interaction-event.mjs";

const containerCss = () => css`
  background-color: var(--shade8);
  border: 1px solid var(--shade3-50);
  border-radius: var(--br-lg);

  display: flex;
  flex-direction: row;
  justify-items: flex-start;

  & > * {
    display: flex;
  }
`;

const tabContainerCss = () => css`
  &:not(:last-child) {
    border-right: 1px solid var(--shade3-50);
  }

  &:hover {
    background-color: var(--shade6);
  }

  &.active {
    > label {
      color: var(--shade0);
    }
    background-color: var(--shade6);
  }
`;

const tabLabelCss = () => css`
  padding: var(--sp-1);
  color: var(--shade2);
  cursor: pointer;

  .active & {
    color: var(--shade0);
  }

  .platform-icon {
    width: var(--sp-6);
    height: var(--sp-6);
  }

  ${mobileLarge} {
    .platform-description {
      display: none;
    }
  }
`;

type PlatformValue = ElementOf<typeof ValorantConsoleData>["key"];

const SELECT_PLATFORM = ["common:selectPlatform", "Select Platform"] as const;

interface ConsoleSelectorProps {
  placement?: string;
}

export default function ConsoleSelector({ placement }: ConsoleSelectorProps) {
  const sendEvent = useInteractionEvent("console-selector");
  const { consoleParam, setConsoleParam } = useConsoleState();
  const { t } = useTranslation();
  const tabOptions = useMemo(() => {
    return ValorantConsoleData.map<
      ElementOf<TabsProps<PlatformValue>["tabOptions"]>
    >((plat) => ({
      name: plat.key,
      content: (
        <div className="flex row justify-between gap-2 pad-0-3">
          <plat.icon className="platform-icon" />
          <span className="platform-description">{t(...plat.display)}</span>
        </div>
      ),
    }));
  }, [t]);

  const activeTab = useMemo(
    () => tabOptions.findIndex((v) => v.name === consoleParam),
    [consoleParam, tabOptions],
  );

  const onChange = useCallback(
    (idx: number) => {
      const option = tabOptions?.[idx];
      if (!option) return;
      sendEvent(
        "select-platform",
        {
          isConsole: option.name,
          placement,
        },
        "click",
      );
      setConsoleParam(option.name);
    },
    [setConsoleParam, tabOptions],
  );

  return (
    <Tabs<PlatformValue>
      onChange={onChange}
      tabOptions={tabOptions}
      activeTab={activeTab}
      label={t(...SELECT_PLATFORM)}
      classes={{
        containerClass: containerCss(),
        tabContainerClass: tabContainerCss,
        tabLabelClass: tabLabelCss(),
      }}
    />
  );
}
