import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { winRatecolorRange } from "@/app/util.mjs";
import ActRankTriangle from "@/game-val/ActRankTriangle.jsx";
import { GAME_VAL_RANK_COLORS } from "@/game-val/constants.mjs";
import {
  calcAvgScore,
  getActOptions,
  getGameModes,
  getValorantRankImage,
  isQueueDeathmatch,
  translateValRankedTier,
} from "@/game-val/utils.mjs";
import { ValWinStreakBadge } from "@/game-val/WinStreakBadge.jsx";
import DefaultWinrate from "@/shared/DefaultWinrate.jsx";
import RadialProgress from "@/shared/RadialProgress.jsx";
import { calcRate, sanitizeNumber } from "@/util/helpers.mjs";
import { getLocaleRate, getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const RankMajorFrame = styled("div")`
  &:not(:last-of-type) {
    margin-bottom: var(--sp-6);
  }
`;

const StatsArea = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--sp-4) var(--sp-6);
  padding-bottom: var(--sp-4);
  text-transform: capitalize;
`;

const StatLine = styled("div")`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .stat-name {
    color: var(--shade2);
    font-weight: 500;
    font-size: var(--sp-3);
  }
  .hs-stat {
    display: flex;
    color: var(--shade2);
    font-weight: 500;
    font-size: var(--sp-3);
  }
  .stat-data-point {
    font-weight: 700;
    font-size: var(--sp-3_5);
  }
`;

const RankInfo = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  margin: var(--sp-2_5) 0 var(--sp-6) 0;
  padding-bottom: ${(props) => (props.$stats ? "" : "var(--sp-5)")};
`;

const PlayerRank = styled("div")`
  position: relative;
  margin-top: var(--sp-3);
  display: flex;
`;

const PlayerRankIcon = styled("div")`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
`;

const PlayerRankDetails = styled("div")`
  margin-left: var(--sp-4);
  color: var(--shade0);
`;

const Body1FormActive = styled((props) => (
  <p {...props} className={`type-body1-form--active ${props.className}`} />
))``;

const RankTier = styled(Body1FormActive)`
  color: ${({ color }) => (color ? color : "inherit")};
`;

const Points = styled("p")`
  font-size: var(--sp-3);
  color: var(--shade2);
  font-weight: bold;

  span {
    margin-left: var(--sp-2);
  }
`;

const WinRate = styled("span")`
  color: var(--shade1);
`;

const FilterSelectContainer = styled("div")`
  display: flex;
  margin-bottom: var(--sp-6);
  gap: var(--sp-2);
  flex-wrap: wrap;
`;

const RankContainer = styled("div")`
  display: inline-flex;
  align-items: center;
  margin-bottom: var(--sp-1);
`;

const RankIcon = ({ rank, tier, radialPercent, radialColor }) => {
  return (
    <PlayerRank>
      <RadialProgress
        size={108}
        background={"var(--shade6)"}
        data={[radialPercent]}
        colors={[radialColor ? radialColor : "var(--shade6)"]}
        strokeWidth={4}
      />
      <PlayerRankIcon>
        <img src={getValorantRankImage({ tier, rank })} width="72" />
      </PlayerRankIcon>
    </PlayerRank>
  );
};

const RankIconWrapper = ({
  queue,
  radialPercent,
  radialColor,
  isActive,
  selectedRankObj,
}) => {
  if (isActive) {
    return (
      <RankIcon
        rank={queue?.rank}
        tier={queue?.tier}
        radialColor={radialColor}
        radialPercent={radialPercent}
      />
    );
  }
  return <ActRankTriangle actRankObj={selectedRankObj} />;
};

function isTierString(tier) {
  if (typeof tier === "string") return true;
  return false;
}

const rankTitle = (t, tier) => {
  if (tier && isTierString(tier)) {
    return translateValRankedTier(t, tier);
  }
  return t("val:ranks.unrated", "Unrated");
};

function RankMajor({
  queue,
  seasonValue,
  stats,
  setSeason,
  queueName,
  setQueue,
  winstreak,
  winRateColor,
  actRanks,
  activeSeasonId,
  isConsole,
}) {
  const { t } = useTranslation();
  const {
    val: { content },
  } = useSnapshot(readState);

  const selectedRankObj =
    seasonValue === activeSeasonId
      ? queue
      : actRanks.find((a) => a.actId === seasonValue);

  const damagePerRound = stats ? getLocaleString(stats?.damagePerRound) : null;

  const kd = stats
    ? getLocaleString(calcRate(stats?.kills || 0, stats?.deaths || 0, 2))
    : null;

  const econScore = getLocaleRate(stats?.econRating);

  const hsPercent = calcRate(
    sanitizeNumber(stats?.headshotPercent || 0),
    100,
    3,
  );
  const combatScore = getLocaleString(
    stats ? calcAvgScore(stats.score, stats.roundsPlayed) : null,
  );

  const isDeathmatch = isQueueDeathmatch(queue);
  const games = 0;
  const wins = queue?.wins;
  const losses = queue?.losses;

  const colors = GAME_VAL_RANK_COLORS;

  const queueWinrate = calcRate(wins, wins + losses, 2);
  const rankName = selectedRankObj?.tier?.toLowerCase();

  const winRateColors =
    winRateColor ||
    (wins !== undefined &&
      winRatecolorRange(100 * (wins / (wins + losses || 1))));

  let radialPercent = 0;
  if (queue) {
    if (selectedRankObj?.tier === "radiant") radialPercent = 1;
    else if (queue.rankedRating !== null && queue.rankedRating !== undefined) {
      radialPercent = queue.rankedRating / 100;
    }
  }

  const radialColor =
    queue && isTierString(selectedRankObj?.tier)
      ? colors[rankName].fill
      : "var(--shade6)";

  const titleColor =
    queue && isTierString(selectedRankObj?.tier)
      ? colors[rankName].text
      : "var(--shade3)";

  const tierTitle = rankTitle(t, selectedRankObj?.tier);

  const acts = getActOptions(content?.acts || []);

  const selectActOptions = acts.map((act) => {
    const playerRankInfo =
      act.key === activeSeasonId
        ? queue
        : actRanks.find((a) => a.actId === act.key);

    return {
      ...act,
      value: act.key,
      image: getValorantRankImage({
        tier: playerRankInfo?.tier,
        rank: playerRankInfo?.rank,
      }),
    };
  });
  let currentRR = 0;
  if (queue?.rankedRating)
    currentRR = queue.rankedRating >= 0 ? queue.rankedRating : 0;
  const isActive = seasonValue === activeSeasonId;
  const GAME_MODES_OPTIONS = useMemo(
    () => getGameModes(false, isConsole),
    [isConsole],
  );
  return (
    <RankMajorFrame>
      <FilterSelectContainer>
        <Select
          selected={queueName}
          options={GAME_MODES_OPTIONS}
          onChange={setQueue}
        />
        <Select
          selected={seasonValue}
          options={selectActOptions}
          onChange={setSeason}
        />
      </FilterSelectContainer>
      <RankInfo $stats={stats}>
        {queue ? (
          <RankIconWrapper
            queue={queue}
            radialColor={radialColor}
            radialPercent={radialPercent}
            selectedRankObj={selectedRankObj}
            isActive={isActive}
          />
        ) : (
          <DefaultWinrate wins={wins} games={games} />
        )}
        <PlayerRankDetails>
          <div>
            <RankContainer>
              <RankTier color={titleColor}>
                {tierTitle} {!!selectedRankObj?.rank && selectedRankObj?.rank}
              </RankTier>
              &nbsp;&nbsp;
              {winstreak > 1 && isActive && (
                <ValWinStreakBadge
                  streakCount={winstreak}
                  streakType="win"
                  showTooltip
                />
              )}
            </RankContainer>
            {queue?.rankedRating && isActive ? (
              <Points>
                {t("val:rankedRating", "{{points}} RR", {
                  points: currentRR,
                })}
              </Points>
            ) : null}
            <Points>
              {wins !== undefined &&
                losses !== undefined &&
                t("val:winsAndLosses", "{{wins}}W {{losses}}L", {
                  wins: wins || 0,
                  losses: losses || 0,
                })}
              <WinRate style={{ color: winRateColors }}>
                {t("common:percent", "{{percent, percent}}", {
                  percent: queueWinrate,
                })}
              </WinRate>
            </Points>
          </div>
        </PlayerRankDetails>
      </RankInfo>
      {stats && (
        <StatsArea>
          <StatLine>
            <p className="stat-name">
              {t("val:stats.killsDeath", "Kills/Death")}
            </p>
            <p className="stat-data-point">{kd}</p>
          </StatLine>

          {!isDeathmatch && (
            <StatLine>
              <p className="stat-name">
                {t("val:stats.dmgPerRound", "Dmg/Round")}
              </p>
              <p className="stat-data-point">{damagePerRound}</p>
            </StatLine>
          )}

          {!isDeathmatch && (
            <StatLine>
              <p className="hs-stat">{t("common:stats.hs", "HS")}</p>
              <p className="stat-data-point">
                {t("common:percent", "{{percent, percent}}", {
                  percent: hsPercent,
                })}
              </p>
            </StatLine>
          )}
          <StatLine>
            <p className="stat-name">
              {t("val:stats.combatScore", "Combat Score")}
            </p>
            <p className="stat-data-point">{combatScore}</p>
          </StatLine>
          {!isDeathmatch && (
            <StatLine>
              <p className="stat-name">
                {t("val:stats.econScore", "Econ Score")}
              </p>
              <p className="stat-data-point">{econScore}</p>
            </StatLine>
          )}
        </StatsArea>
      )}
    </RankMajorFrame>
  );
}
export default RankMajor;
